import {
  type DtoPromptTemplate,
  EnumsPromptTemplateType,
} from '@lp-lib/api-service-client/public';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';

export class PromptTemplateUtils {
  static OwnedBySystem(template: Nullable<DtoPromptTemplate>): boolean {
    return template?.ownerType === 'System';
  }

  static Editable(
    template: Nullable<DtoPromptTemplate>,
    superAdmin = getFeatureQueryParam('super-admin')
  ): boolean {
    return superAdmin || !this.OwnedBySystem(template);
  }

  static HintForType(type: EnumsPromptTemplateType) {
    switch (type) {
      case EnumsPromptTemplateType.PromptTemplateTypeSimple:
        return 'For simple chat conversation, if you are not sure, use this one.';
      case EnumsPromptTemplateType.PromptTemplateTypeAssistant:
        return 'Equivalent to the OpenAI Assistant, it has advanced support such as file search.';
      default:
        return 'Unknown type';
    }
  }

  static ValidType(type: string | null): type is EnumsPromptTemplateType {
    if (!type) return false;
    return Object.values(EnumsPromptTemplateType).includes(
      type as unknown as EnumsPromptTemplateType
    );
  }

  static TypeOptions() {
    return [
      {
        label: 'Text Generation',
        value: EnumsPromptTemplateType.PromptTemplateTypeSimple,
        desc: 'Text generation, the most common template you need.',
      },
      {
        label: 'Prompt Snippet',
        value: EnumsPromptTemplateType.PromptTemplateTypeSnippet,
        desc: 'Prompt Snippet, a piece of text that can be reused in different prompts.',
      },
      {
        label: 'Image Generation',
        value: EnumsPromptTemplateType.PromptTemplateTypeImageGen,
        desc: 'Image generation, create images with prompts.',
      },
      {
        label: 'OpenAI Assistant',
        value: EnumsPromptTemplateType.PromptTemplateTypeAssistant,
        desc: "OpenAI Assistant, it's only used in custom games for LP classic experience.",
      },
    ];
  }

  static SupportFileSearch(type: EnumsPromptTemplateType) {
    return type === EnumsPromptTemplateType.PromptTemplateTypeAssistant;
  }

  static SupportCustomFacts(type: EnumsPromptTemplateType) {
    return type === EnumsPromptTemplateType.PromptTemplateTypeSimple;
  }

  static HasFeatureExtensions(type: EnumsPromptTemplateType) {
    return this.SupportFileSearch(type) || this.SupportCustomFacts(type);
  }

  static SupportFunctionCall(type: EnumsPromptTemplateType) {
    return (
      type === EnumsPromptTemplateType.PromptTemplateTypeSimple ||
      type === EnumsPromptTemplateType.PromptTemplateTypeAssistant
    );
  }

  static SupportMultiMessages(type: EnumsPromptTemplateType) {
    return (
      type === EnumsPromptTemplateType.PromptTemplateTypeSimple ||
      type === EnumsPromptTemplateType.PromptTemplateTypeAssistant
    );
  }

  static IsSnippet(type: EnumsPromptTemplateType) {
    return type === EnumsPromptTemplateType.PromptTemplateTypeSnippet;
  }
}
